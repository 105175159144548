import React from 'react';
import { Link } from 'gatsby'
import careersCardStyles from './careers-card.module.scss';

const CareersCard = ({ data, slug }) => {
  return (
    <div className={careersCardStyles.positionCard}>
      <div>
        <Link to={`/careers/${slug}`} ><h4>{data.position_title}</h4></Link>
        <span>@Sofia, Bulgaria</span>
      </div>
      <Link to={`/careers/${slug}`} className="primary-btn">Full description</Link>
    </div>
  );
};

export default CareersCard;